<template>
  <div class="login-wrap">
    <img src="../../assets/img/login-title.png" class="bg-logo" style="position: absolute;top:30px;left: 30px;">
    <div class="login-title">
      <img src="../../assets/img/login-tip.png">
    </div>
    <div class="ms-login wechat">
      <wxlogin :appid="'wxe329b773b6d780c7'" :scope="'snsapi_login'" :state="state" :theme="'black'" :code="'qcck'" :redirect_uri="
        encodeURIComponent('https://qc.kulf.cn/authback')
      " :href="
  'data:text/css;base64,LmltcG93ZXJCb3ggLnFyY29kZSB7d2lkdGg6IDE4MHB4O30KLmltcG93ZXJCb3ggLmluZm8ge3dpZHRoOiAyMDBweDt9Ci5zdGF0dXNfaWNvbiB7ZGlzcGxheTogbm9uZX0KLmltcG93ZXJCb3ggLnN0YXR1cyB7dGV4dC1hbGlnbjogY2VudGVyO3BhZGRpbmc6MCAxNHB4fSA='
" rel="external nofollow" />
    </div>


  </div>

</template>

<script>
//import { login } from "../../api";
import wxlogin from "vue-wxlogin";
export default {
  components: {
    wxlogin
  },
  data() {
    return {
      state: 'open_login'
    };
  },
  mounted() {
  },
  methods: {
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>